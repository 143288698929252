@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200;300;400;500;600;700;800&family=Fira+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
/* Lato */


:root {
    --primary: #eab70f;
    --secondary: rgb(0, 168, 59);
    --gradient: 90deg, #fff, #000;
}

*{
    margin: 0;
    padding: 0;
    font-family: 'Dosis', sans-serif;
}

*::selection{
    background:#212529;
    color: var(--primary);
    font-weight: lighter !important;
}


textarea:focus{
    border-color: var(--primary) !important;
}

a{
    text-decoration: none !important;
}

h1 > span,h1,h2,h3{
    font-family: 'Fira Sans', sans-serif !important;
}

#root{
    background-color: #000;
    color: rgba(248,249,250,1);
    scroll-behavior: smooth;
}

body::-webkit-scrollbar, #results::-webkit-scrollbar{
    width: 10px;
}

body::-webkit-scrollbar-track{
    background-color: #212529;
}

body::-webkit-scrollbar-thumb, #results::-webkit-scrollbar-thumb{
    border-radius: 25px;
}

body::-webkit-scrollbar-thumb, #results::-webkit-scrollbar-thumb{
    background-color: var(--primary);
}

#results::-webkit-scrollbar-track{
    background-color: #fff;
}

.loading-page{
    width: 100vw;
    height: 100vh;
}

.color-primary{
    color: var(--primary);
}

.border-color-primary{
    border: 1px solid var(--primary) !important;
}

.bg-primary-root{
    background-color: var(--primary);
}

.no-event{
    pointer-events: none;
}

.gradient-back{
    background: linear-gradient(90deg, #ff0000, #ffff00, #ff00f3, #0033ff, #ff00c4, #ff0000);
    background-size: 400%;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 15s linear infinite;
}
.gradient-back-no{
    background: linear-gradient(90deg, #ff0000, #ff00f3, #0033ff, #ff00c4, #ff0000);
    background-size: 400%;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
}





.react-multiple-carousel__arrow{
    background-color: rgba(255, 255, 255, 0.4) !important;
}

.react-multiple-carousel__arrow:hover{
    background-color: rgba(255, 255, 255, 0.6) !important;
}

.black-arrow .react-multiple-carousel__arrow{
    background-color: rgba(0, 0, 0, 0.4) !important;
}

.black-arrow .react-multiple-carousel__arrow:hover{
    background-color: rgba(0, 0, 0, 0.6) !important;
}

.react-multi-carousel-dot button{
    background: transparent !important;
    border-color: grey !important;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button{
    border-color: var(--primary) !important;
}

.h-100vh{
    height: 90vh;
}

/* ################## HEADER ################## */



.img-brand{
    width: auto;
    max-height: 90px;
}


@media screen and (max-width: 767px){
    .img-brand{
        width: auto;
        max-height: 60px;
    }
}

.nav-link{
    z-index: 1;
}

.nav-link.aer-link > a{
    color: #fff !important;
}

.nav-link::before {
    z-index: -1;
    transition: 300ms;
    height: 100%;
    content: "";
    position: absolute;
    bottom: 0 !important;
    left: -1%;
    background-color: #000;
}

.nav-link-ltr::before {
    width: 0%;
    bottom: 10px;
}

.nav-link-ltr:hover::before {
    width: 100%;
}

.aer-link{
    font-size: 18px !important;
    color: rgba(0, 0, 0, 0.575);
}

.aer-link.active, .aer-link:hover{
    color: var(--primary) !important;
}

.aer-color{
    color: var(--primary);
}

.hero-vid{
    width: 100%;
    height: auto;
    max-height: 100vh;
    left: 50%;
    top: 50%;
    object-fit: cover;
}

.aer-img-team{
    width: 21rem;
    height: 17rem;
    overflow: hidden;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    transition: all .7s cubic-bezier(0.55, 0.58, 0, 1.58) !important;
}

@media screen and (max-width: 760px){
    .aer-img-team.sponsor{
        height: 13rem;
    }
}

.aer-btn{
    color: white;
    border: 2px white solid;
    padding: .5px 14px;
    border-radius: 12px;
    margin-top: 8px;

}

.aer-btn:hover{
    background-color: white;
    color: var(--primary);
    font-weight: bold;
}

.aer-img-team-detail{
    opacity: 0;
    transition: all .5s ease-out;
}

.aer-img-team:hover{
    transform: translateY(-3px);
    box-shadow: 0 .5rem 1rem rgba(0,0,0,.15)!important;
}

.aer-img-team:hover .aer-img-team-detail{
    opacity: 1;
}

.aer-vid-team{
    width: 50vw;
    height: 50vh;
    z-index: 20;
}


@media screen and (max-width: 767px) {
    .aer-vid-team{
        width: 80vw;
        height: 50vh;
    }

    #menu-button{
        transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
    }

    .navbar{
        position: absolute !important;
        background-color: #000;
        border-radius: 8px;
        margin: 5em auto;
        left: 2em;
        right: 2em;
        width: 100% !important;
        height: 100vh;
        opacity: 0;
        transform: translate(0, -100vh) !important;
        transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
    }
    
    .show-nav{
        transform: translate(-2rem, 57vh) !important;
        width: 80%;
        opacity:1;
        /* height: auto; */
    }


    .nav-link{
        display: block !important;
        width: 90%;
        margin-top: 1rem;
        margin-left: 3rem !important;
    }

}

.sub{
    position: fixed;
    left: 5rem;
    right: 5rem;
    z-index: -2000;
    display: none;
    padding: 1em;
    min-width: 160px;
    background-color: #000;
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,0.175);
    box-shadow: 0 6px 12px rgba(0,0,0,0.175);
}

.nav-link:hover .sub{
    display: flex !important;
    justify-content: center;
    align-items: center;
}

.accordion{
    --bs-accordion-border-color: transparent !important;
    --bs-accordion-border-width: 0 !important;
    --bs-accordion-bg: #000 !important;
    --bs-accordion-btn-icon: url(../../assets/img/svg/arrow-down-s-line.svg) !important;
    --bs-accordion-btn-active-icon: url(../../assets/img/svg/arrow-down-s-line.svg) !important;
}

.accordion-button:not(.collapsed) {
    color: #ffffff9d !important;
}

.accordion-header button:focus {
    border-color: transparent !important;
    box-shadow: none !important;
}

.sub-nav-mobile.offcanvas{
    display: flex !important;
    flex-direction: column !important;
}

.offcanvas-body .accordion-body {
    padding: 0;
}

.offcanvas-body{
    overflow-x: hidden;
}

.offcanvas-body img{
    margin-top: auto;
}

.offcanvas-body .accordion-button{
    font-family: 'Dosis', sans-serif !important;
}

.offcanvas-body .accordion-body, .offcanvas-body .accordion-button {
    text-transform: capitalize;
    font-size: 1.18rem;
    background-color: #000 !important;
    color: #fff !important;
}

.offcanvas-body::-webkit-scrollbar {
    width: 5px;
    padding-right: 8px;
}

.offcanvas-body::-webkit-scrollbar-track{
    background-color: transparent;
}

.offcanvas-body::-webkit-scrollbar-thumb{
    border-radius: 25px;
    background-color: #111;
}

.offcanvas-body::-webkit-scrollbar-thumb{
    background-color: #ffffff9d;
}





/* ################## FOOTER ################## */

.bg-foot{
    padding-bottom: 226px !important;
    position: relative;
    overflow-x: hidden;
}

.gif{
    background: url(../../assets/gif/footer_bg.png) no-repeat scroll center 0;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 256px;
}

.car{
    background: url(../../assets/gif/car-gif-png-1.gif) no-repeat center center;
    width: 170px;
    height: 60px;
    background-size: 100%;
    position: absolute;
    bottom: 0;
    left: 30%;
    -webkit-animation: myfirst 9s linear infinite;
    animation: myfirst 9s linear infinite;
}

@media screen and (max-width: 767px){
    .car{
        -webkit-animation: myfirst 5s linear infinite;
        animation: myfirst 5s linear infinite;
    }
}

@keyframes myfirst{
    0% {
        left: -25%;
    }
    100% {
        left: 100%;
    }
}

.eventify-social{
    font-size: 1.7em !important;
}

@media screen and (max-width: 767px){
    .eventify-social{
        font-size: .8em !important;
    }

    .social-logo{
        right: .5rem;
    }
}







/* ################## TITLE ################## */

.title h2{
    position: relative;
    padding: 0;
    margin: 0;
    font-family: "Fira Sans", sans-serif !important;
    font-weight: 300;
    color: #080808;
    -webkit-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;

}

.title h2:before {
    background-color: var(--primary);
    border-radius: 0.25rem;
    content: '';
    display: block;
    height: 0.25rem;
    width: 42px;
    margin-bottom: 1.25rem;
    }






/* ################## HOME ################## */

.show-link small{
    display: none;
}

.show-link{
    text-decoration-color: var(--primary)
}

.show-link:hover{
    color: var(--primary) !important;
}

.show-link:hover small{
    display: inline;
}


.bg-promo{
    background: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,0.3), rgba(0,0,0,0.3), rgba(0,0,0,0.4), rgba(0,0,0,0.6), rgba(0,0,0,0.7), rgba(0,0,0,0.8), rgba(0,0,0,0.8));
    transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
}

.bg-promo:hover{
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0), rgba(0,0,0,.2), rgba(0,0,0,.2), rgba(0,0,0,0.3), rgba(0,0,0,0.5), rgba(0,0,0,0.6), rgba(0,0,0,0.7), rgba(0,0,0,0.7));
}

.bg-actu{
    bottom: 20px;
    left: 20px;
    margin-right: 5rem;
}

.bg-actu small{
    display: block;
}



@media screen and (max-width: 767px){
    .sponsor.position-sticky{
        position: relative !important;
    }

}

.top-stic{
    top: 140px;
}

@media screen and (max-width: 767px){
    .top-stic{
        top: auto;
    }
}

.sponsor-card > .aer-img-team-mobile{
    height: 15rem;
}

.sponsor > .w-100 > iframe{
    height: 900px;
}

@media screen and (max-width: 767px){
    .sponsor-card > .aer-img-team-mobile{
        height: 19rem;
    }

    .sponsor > .w-100 > iframe{
        height: 600px;
    }
}

@media screen and (max-width: 320px){
    .sponsor-card > .aer-img-team-mobile{
        height: 13rem;
    }

    .sponsor > .w-100 > iframe{
        height: 400px;
    }
}

.team-img{
    position: relative;
    padding: 30px 17px;
    background: url(../../assets/img/partner/line.png) center top no-repeat;
    background-size: 21rem;
    background-position: center;
    transition: 0.2s;
}

.aer-img-team-mobile{
    width: auto;
    height: 12rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.team-img img {
    max-width: auto;
    height: 12rem;
}

.card.border{
    transform: translateX(-10px);
}

.btn-vid{
    background-color: var(--primary);
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
}

.timer{
    background: #ffffff9d;
    backdrop-filter: blur(7px);
}

.timer span{
    font-size: 1.9rem;
}

.timer p{
    font-size: 1rem !important;
}

@media screen and (max-width: 768px){
    .spanTimer{
        font-size: 1.5rem !important;
    }

    .timer span{
        font-size: 1.7rem;
    }
    
    .timer p{
        font-size: .9rem !important;
    }
}

@media screen and (max-width: 576px){
    .spanTimer{
        font-size: 1.1rem !important;
    }

    .timer h1{
        font-size: 1.6rem
    }

    .timer span{
        font-size: 1.3rem;
    }
    
    .timer p{
        font-size: .7rem !important;
    }
}

@media screen and (max-width: 375px){
    .spanTimer{
        font-size: .9rem !important;
    }

    .timer h1{
        font-size: 1.2rem
    }

    .timer span{
        font-size: .9rem;
    }
    
    .timer p{
        font-size: .5rem !important;
    }

    .timer a span{
        font-size: 1.2rem !important;
    }
}

.arrow-register {
    animation: slide2 1s ease-in-out infinite;
}

@keyframes slide2 {
    0%,
    100% {
        transform: translate(0, 0) rotate(-45deg);
    }

    50% {
        transform: translate(10px, -10px) rotate(-45deg);
    }
}

.logo-customer{
    height: 180px;
}

#clt{
    position: absolute;
    bottom: -55px;
    right: 0;
    left: 0;
    margin: auto 10%;
    z-index: 99;
}

.link-customer{
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1.3px solid rgb(180, 178, 178);
    border-radius: 8px;
    text-decoration: none;
    font-size: 1.1rem;
    color: rgb(180, 178, 178);
    transition: all .3s ease;
}

.link-customer:hover{
    border-color: var(--primary);
    background-color: var(--primary);
    color: white;
}

.customer-card{
    height: 100%;
    transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
    animation: animate 1.7s ease-in infinite;
}

.customer-card:hover{
    transform: scale(1.01);
    box-shadow:  0 1rem 1.5rem rgba(0,0,0,.175)!important;
}

.customer-card img{
    margin: 0 auto;
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 140px;
}

@keyframes animate {
    0% {
        transform: translateY(3px);
    }
    
    50% {
        transform: translateY(-3px);
    }

    100% {
        transform: translateY(3px);
    }
}













/* ################### PHOTOS 3D #################### */


.gallery-rotate{
    position:relative;
    width: 310px;
    height: 331px;
    transform-style:preserve-3d;
    animation:rotate 80s linear infinite;
}

@keyframes rotate{
    from{
        transform:perspective(1200px) rotateY(0deg);
    }
    
    to{
        transform:perspective(1200px) rotateY(360deg);
    }
}

.gallery-rotate span{
    position:absolute;
    width:100%;
    height:100%;
    transform-origin:center;
    transform-style:preserve-3d;
    transform:rotateY(calc(var(--i) * 45deg)) translateZ(380px);
}

.gallery-rotate span img{
    position:absolute;
    width:100%;
    height:100%;
    object-fit:contain;
}

@media screen and (max-width: 420px) {
    .gallery-rotate{
        width: 230px;
        height: 331px;
    }

    .gallery-rotate span{
        transform:rotateY(calc(var(--i) * 45deg)) translateZ(380px);
    }
}













/* ################## EAR KRIBI ################## */

.hero-slide{
    width: 100%;
    height: auto;
    max-height: 70vh;
}

.img-fluid.img{
    height: 70vh !important;
}

@media (max-width: 1024px) {
    .img-fluid.img{
        height: auto !important;
    }
}

.gradient-back{
    background: linear-gradient(90deg, #ff0000, #ffff00, #ff00f3, #0033ff, #ff00c4, #ff0000);
    background-size: 400%;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    -webkit-background-clip: text;
    animation: gradient 15s linear infinite;
}

@keyframes gradient{
    0%{
        background-position: 0%;
    }
    100%{
        background-position: 400%;
    }
}









/* ################## ALBUM ################## */

#bg-tv{
    background: linear-gradient(rgba(0, 0, 0, 0.725),rgba(0, 0, 0, 0.725)) , url(../../assets/img/bg_tv.webp) fixed;
    background-size: contain;
    width: 100%;
    border-radius: 0 0 16% 16%;
}

@media screen and (max-width: 767px){
    #bg-tv{
        background: linear-gradient(rgba(0, 0, 0, 0.725),rgba(0, 0, 0, 0.725)) , url(../../assets/img/bg_tv.webp) fixed;
        background-size: contain;
    }
}

.album{
    scroll-behavior: smooth;
}

.album img{
    width: 100%;
    max-height: 98vh;
}

.gallery{
    -webkit-column-count: 4;
    -moz-column-count: 4;
    column-count: 4;
    -webkit-column-width: 25%;
    -moz-column-width: 25%;
    column-width: 25%;
    padding: 0 12px;
}

@media (max-width: 991px) {
    .gallery{
        -webkit-column-count: 3;
        -moz-column-count: 3;
        column-count: 3;
    }
}

@media (max-width: 480px) {
    .gallery{
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
}

.gallery .pics{
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    margin-bottom: 12px;
    transition: all .6s ease;
}

.gallery .pics:hover{
    filter: opacity(.8);
}

.download{
    display: none !important;
    transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
}

.pics:hover .download{
    display: flex !important;
}

.nav-pills .nav-link.active{
    color: #fff !important;
    background-color: var(--primary) !important;
}

.nav-pills .nav-link{
    color: var(--primary) !important;
    background-color: transparent !important;
}

/* Modal overlay */
.overlay{
    position: fixed;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1500;
    background: rgba(1, 1, 1, 0.8);
}

.overlay img{
    display: block;
    max-width: 90%;
    margin: 60px auto;
    box-shadow: 3px 5px 7px rgba(255, 255, 255, 0.5);
}

.overlay > span{
    top: 20px;
    right: 20px;
}

.overlay-arrows__right{
    display: flex;
    position: absolute;
    justify-content: space-between;
    background-color: #6d6d6d;
    top: 50%;
    right: 0;
    z-index: 999;
    cursor: pointer;
}

.overlay-arrows__left{
    display: flex;
    position: absolute;
    justify-content: space-between;
    background-color: #6d6d6d;
    top: 50%;
    left: 0;
    z-index: 999;
    cursor: pointer;
}












/* ################## INSCRIPTION PARTICIPANTS ################## */


input[type="text"]:focus, input[type="email"]:focus, .form-control:focus{
    box-shadow: none;
    border: 1px solid var(--primary);
}

.form-check-input:checked{
    background-color: var(--primary) !important;
    border-color: var(--primary) !important;
    box-shadow: none !important;
}

.inputBox{
    position: relative;
    margin-top: 1rem;
}
.inputBox input{
    position: relative;
    width: 100px;
    padding: .35rem .75rem !important;
    background: transparent;
    outline:none;
    border: 1px solid #dee2e6;
    border-radius: .375REM;
    color: #fff;
    font-size: 1rem;
    transition: .5s;
    z-index: 10;
}

.inputBox span{
    position: absolute;
    left: 0;
    margin: .35rem .75rem;
    pointer-events: none;
    color: #8f8f8f;
    font-size: 1rem;
    transition: .5s;
}

form .inputBox input:valid~span, 
form .inputBox input:focus~span{
    color: var(--primary);
    font-size: .9rem;
    background-color: #000;
    margin: .35rem .75rem;
    margin-bottom: 1rem;
    z-index: 10;
    transform: translateY(-1.1rem);
}


.inputBox i{
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px;
    border-radius: .375rem;
    overflow: hidden; 
    pointer-events: none;
}

.inputBox input:valid ~ i, 
.inputBox input:focus ~ i{
    height: 44px;
}

input[type="submit"]{
    background: var(--primary) !important;
    border: 2px var(--primary) solid !important;
    color: white;
    transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
}

input[type="submit"]:hover{
    background: #000 !important;
    color: #fff;
    transform: translateY(-3px);
}

input[type="reset"]{
    background: #000 !important;
    border: 2px var(--primary) solid !important;
    color: #fff;
    transition: all .6s cubic-bezier(.87,-.41,.19,1.44);
}

input[type="reset"]:hover{
    background: var(--primary) !important;
    border: 2px var(--primary) solid !important;
    color: white;
    transform: translateY(-3px);
}








/* ##################  A PROPOS - CONTEXTE ################## */

.context-text{
    text-align: justify;
    line-height: 1.6em;
    letter-spacing: .05em;
    font-size: 1.2em;
    padding: .2rem .4rem;
    border: 1rem solid var(--primary);
    -o-border-image: repeating-linear-gradient(45deg, transparent, transparent 5px, var(--primary) 6px, var(--primary) 15px, transparent 16px, transparent 20px) 10/.5rem;
        border-image: repeating-linear-gradient(45deg, transparent, transparent 5px, var(--primary) 6px, var(--primary) 15px, transparent 16px, transparent 20px) 10/.5rem;
}









/* ##################  A PROPOS - MEMEBRES ################## */

.nav-member{
    top: 8.2rem;
    z-index: 1029;
}

.nav-member .nav-link.active{
    position: relative;
    border-radius: 0px;
}

.nav-member .nav-link.active::after{
    content:"";
    width: 20px;
    height: 20px;
    margin-top: -5px;
    background: var(--primary);
    display: block;
    position: absolute;
    left: 50%;
    right: 50%;
    transform: rotate(45deg);
    z-index: -1;
}

@media (max-width: 768px) {
    .nav-member{
        top: 6.7rem;
    }
    
    .member-card{
        transform: translateX(-12px)
    }
}

@media (max-width: 425px) {
    .nav-member{
        top: 6.8rem;
    }

    .nav-member a{
        padding-left: .1rem;
        padding-right: .1rem;
    }
}

.silver-card{
    background: linear-gradient(180deg, #ededed, #bdbdbd)
}

.input-hidden:checked + label>div {
    padding: 2px;
    filter: contrast(150%);
}

.input-hidden + label>div {
    cursor: pointer;
    transition: 300ms all;
    filter: brightness(0.6) grayscale(100%);
}










/* ################## ACTUS ################## */
.actu-img{
    width: 100%;
    max-height: 25rem;
}

.actu-img img {
    --s: 8px;  /* size of the frame */
    --b: 1px;   /* border thickness */
    --w: 600px; /* width of the image */
    --c: var(--primary);
    
    width: var(--w);
    aspect-ratio: 1;
    object-fit: cover;
    padding: calc(2*var(--s));
    --_g: var(--c) var(--b),#0000 0 calc(100% - var(--b)),var(--c) 0;
    background:
        linear-gradient(var(--_g)) 50%/100% var(--_i,100%) no-repeat,
        linear-gradient(90deg,var(--_g)) 50%/var(--_i,100%) 100% no-repeat;
    outline: calc(var(--w)/2) solid #0009;
    outline-offset: calc(var(--w)/-2 - 2*var(--s));
    transition: .4s;
    cursor: pointer;
}

.actu-img img:hover {
    outline: var(--b) solid var(--c);
    outline-offset: calc(var(--s)/-2);
    --_i: calc(100% - 2*var(--s));
}

@media (max-width: 768px) {
    .actu-img{
        max-height: 25rem
    }
    
    .member-card{
        transform: translateX(-12px)
    }
}

@media (max-width: 425px) {
    .actu-img{
        max-height: 20rem
    }
}



/* search bar */

.search-news{
    right: 20px;
    top: 10px;
    z-index: 1031;
}

.img-show{
    width: 5rem !important;
    height: 5rem !important;
}

.title-show{
    width: calc(100vw - (100vw - 1320px) - 5rem - 270px) !important;
    white-space: nowrap !important;
    text-overflow: ellipsis !important;
    overflow: hidden !important;
}

@media screen and (max-width: 1200px) {
    .title-show{
        width: calc(100vw - (100vw - 1140px) - 5rem - 270px) !important;
    }
}

@media screen and (max-width: 992px) {
    .title-show{
        width: calc(100vw - (100vw - 960px) - 5rem - 320px) !important;
    }
}

@media screen and (max-width: 767px) {
    .title-show{
        width: calc(100vw - (100vw - 720px) - 5rem - 270px) !important;
    }
}

@media screen and (max-width: 576px) {
    .title-show{
        width: calc(100vw - 5rem - 80px ) !important;
    }
}

.overlay .form-control:focus{
    box-shadow: none !important;
}

.overlay{
    position: fixed;
    display: flex;
    padding-top: 50px;
    align-items: first baseline;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1300;
    background: rgba(1, 1, 1, 0.8);
}

#results{
    overflow: auto;
    overflow-x: hidden;
    max-height: calc(100vh - 50px - 0.5rem - 3rem - 1rem - 5rem);
}

.bg-facebook{
    background-color: #4473e7 !important;
}

.bg-linkedin{
    background-color: #4476b1 !important;
}